const common = {
  all: "All",
  activeF: "Active",
  active: "Active",
  add: "Add",
  address: "Address",
  agencies: "Offices",
  agency: "Office",
  attachment: "Application for Payment",
  attachments: "Applications for Payment",
  amount: "Amount",
  bpu: "Rate Card",
  bpuTemplates: "Rate Card Templates",
  cancel: "Cancel",
  category: "Category",
  close: "Close",
  code: "Code",
  city: "Town",
  comments: "Comments",
  company: "Company",
  connection: "Log in",
  contactInformation: "Mailing address",
  contractor: "Contractor",
  contractorAgreement: "Contractor Agreement",
  create: "Create",
  createDelegation: "Create a delegation",
  creation: "Creation",
  creator: "Creator",
  currency: "Currency",
  currentCurrency: "GBP",
  date: "Date",
  dashboard: "Dashboard",
  deletion: "Deletion",
  deposits: "Deposits",
  depositType: "Retention",
  description: "Description",
  designation: "Description",
  disconnect: "Log out",
  accountManage: "Gérer le compte",
  dgd: "Final account",
  dsp: "DSP",
  director: "Director",
  display: "Display",
  documents: "Documents",
  download: "Download",
  edifice: "Internal contract reference",
  edition: "Edit",
  elements: "Elements",
  email: "E-Mail",
  eotp: "WBS element",
  eur: "EUR",
  exporter: "Export",
  generate: "Generate",
  help: "Help",
  id: "Id",
  import: "Import",
  imputation: "Cost allocation",
  imputations: "Cost allocations",
  information: "Information",
  mail: "Mail",
  manager: "Managing Director",
  menu: "Menu",
  milestone: "Milestone",
  name: "Name",
  next: "Next",
  node: "Node",
  nro: "POP",
  object: "Object",
  objects: "Objects",
  observation: "Observation",
  on: "on",
  onDate: "on",
  options: "Options",
  order: "Order",
  orders: "Orders",
  payment: "Payment",
  permissions: "Permissions",
  phone: "Phone number",
  pm: "FDH",
  prestations: "Rate Card Item",
  previous: "Previous",
  profile: "Profile",
  project: "Project",
  projects: "Projects",
  quality: "Role",
  quantity: "Quantity",
  quantitatives: "Quantities",
  rad: "RAD",
  reference: "Reference",
  responsible: "Responsable",
  retention: "Retention",
  retentions: "Retentions",
  rg: "Retention",
  role: "Role",
  sap: "SAP",
  save: "Save",
  selection: "Selection",
  send: "Send",
  service: "Service",
  siren: "Company number",
  siret: "Office number (France)",
  status: "Status",
  subcategory: "Sub-category",
  template: "Template",
  to: "to",
  total: "Total",
  transitions: "Transitions",
  type: "Type",
  unit: "Unit",
  upload: "Upload",
  users: "Users",
  vat: "VAT",
  validate: "Validate",
  validator: "Validator",
  visibility: "Visibility",
  workflow: "Workflow",
  contracts: 'Contracts',
  preview: 'Preview',
  delete: 'Delete'
};

const expressions = {
  accessNotGranted: "You don't have the rights to access to this page",
  addDocument: "Add a document",
  addedOn: "Added on",
  contractName: "Contract name",
  createdAt: "Créé le",
  creationDate: "Creation date",
  delete: "Do you really want to delete this element?",
  deleteDocument: "Do you really want to delete this document?",
  endDate: "End date",
  fetchingError: "Error when fetching data",
  fileName: "File name",
  keepProfile: `You are connected on another tab as {0}, do you want to continue with this profile?`,
  leaveWithoutSaving:
    "Do you really want to leave this page without saving your changes?",
  mustBeAfter: "The date must be after the payment notice's date",
  myTasks: "My tasks",
  myScope: "My scope",
  noAccessToEntity: "You don't have access to this entity",
  noReadAccessToEntity: "You don't have reading access to this entity",
  noAttachment: "No Application for Payment",
  noData: "No data to display",
  noFileSelected: "You haven't selected any type or file",
  noPrestation: "No Rate Card Item",
  noUser: "No User",
  problemDescription: "Description of the issue",
  projectName: "Project name",
  projectConcerned: "Project concerned",
  selectFile: "Select a file",
  selectAttachment: "Select an attachment",
  selectedAttachmentName: "Name of the selected attachment",
  selectedFileName: "Selected file name",
  startDate: "Start date",
  templateName: "Template name",
  reportIncidentToSupport: "Report an incident to support",
  reportIncident: "Report an incident",
  triggeredBy: "Triggered by",
  userManuals: "Modes opératoires",
  deactivate: 'Are you sure to deactivate this element ?',
  activate: 'Are you sure to activate this element ?',
  validationDate: "Validation date"
};

const entities = {
  agency: "Office",
  agencies: "Offices",
  attachment: "Application for Payment",
  attachments: "Applications for Payment",
  bpu: "Rate Card",
  bpus: "Rate Cards",
  bpuTemplate: "Rate Card template",
  bpuTemplates: "Rate Card templates",
  company: "Company",
  companies: "Companies",
  contractorAgreement: "Contractor Agreement",
  contractorAgreements: "Contractor Agreements",
  contractTemplate: "Contract templates",
  contractTemplates: "Contract templates",
  documentType: "Type of document",
  documentTypes: "Types of document",
  notifications: "Notifications",
  oscarProject: "OSCAR Project",
  oscarProjects: "OSCAR Projects",
  oscarSubProject: "OSCAR Sub-project",
  oscarSubProjects: "OSCAR Sub-projects ",
  order: "Order",
  orders: "Orders",
  orderTemplate: "Order template",
  orderTemplates: "Order templates",
  paymentGuarantee: "Payment guarantee (France)",
  paymentGuarantees: "Payment guarantees (France)",
  prestation: "Rate card item",
  prestations: "Rate card items",
  prestationReference: "Reference of Rate card item",
  prestationReferences: "References of Rate card item",
  prestationType: "Type of Rate card item",
  prestationTypes: "Types of Rate card item",
  projectType: "Type of projet",
  projectTypes: "Types of projet",
  role: "Role",
  roles: "Roles",
  situation: "Payment Notice",
  situations: "Payment Notices",
  unit: "Unit",
  units: "Units",
  user: "User",
  users: "Users",
  workflowNode: "Workflow node",
  contract: 'Contract'
};

const login = {
  title: "Interface de Suivi Financier",
  username: "Username",
  password: "Password",
  forgotPassword: "Forgot your password?",
};

const home = {
  nothingToValidate: "No Order or Application for Payment to validate.",
  myOrders: "My orders to process",
  myAttachments: "My Applications for Payment to process",
  workflowProcess: "Workflow to process",
  ongoingWork: "Work in progress",
  ongoingOrders: "On-going orders with an outstanding amount of ",
};

const agencies = {
  addUser: "Add a user",
  agencyCreation: "Creation of an Office",
  anomalies: "Anomaly in the agency, incomplete document, collective proceeding or the agency is closed.",
  inviteMailHint: "This mail must be the one from the subcontractor to receive the subscription and reminders.",
  columns: {
    agencyCode: "Office code",
    address: "Office address",
    manager: "Managing Director",
    edifice: "Office",
    inviteMail: "Invite mail"
  },
  administrativeDocuments: 'Administrative documents',
  nolegalDocuments: 'No documents have been retrieved from e-attestation.'
};

const attachments = {
  exportBPU: "Export (All)",
  unrecordedBenefits: "No Rate Card Item",
  amountWoVat: "Amount (excl. VAT)",
  wfStatus: "Workflow status",
  cumulativePreviousAmount: "Cumulative previous amount (excl. VAT)",
  cumulativeAmount: "Cumulative amount (excl. VAT)",
  currentPeriodAmount: "Current period amount (excl. VAT)",
  completionDate: "Completion date",
  totalAmount: "Total order amount (excl. VAT)",
  observationCMD: "Comments (Order)",
  finalBilling: "Décompte définitif",

  prestationColumns: {
    orderQuantity: "Quantity ordered",
    orderAmount: "Amount ordered",
    cumulativeQuantity: "Cumulative quantities",
    cumulativeAmount: "Cumulative amount",
    cumulativePreviousQuantity: "Previous quantities",
    cumulativePreviousAmount: "Previous amount",
    currentQuantity: "Current period quantities",
    currentAmount: "Current period amount",
    help: "Previous + current quantities. The quantities of the next applcations for payment are not accounted here.",
    helpTwo:
      'Quantities of applications for payment with the "Sent for validation" date before the current one.',
  },

  attachmentCreation: "Creation of an Application for Payment",
  validatedOrders: "Validated orders list",
  sameMonthAttachment:
    "You already have an application for payment for this month. Do you want to proceed?",
  prestationSelection: "Rate Card Items selection",
};

const bpus = {
  bpuCreation: "Rate Card Creation",
};

const bpuTemplates = {
  bpuTemplateCreation: "Rate Card Template Creation",
};

const categories = {
  parentCategory: "Parent category",
  mergeDuplicates: "Merge duplicates",
  categoryCreation: "Creation of a category",
};

const companies = {
  agencyCreation: "Office Creation",

  companyCreation: "Company Creation",
  columns: {
    edificeCode: "SAP code",
  },
};

const contractTemplates = {
  addContractTemplate: "Add a contract template",
  downloadContractTemplate: "Download a contract template",
  contractTemplateName: "Contract template name",
};

const dc4 = {
  title: "Suivi des DC4",
  attachmentsPaid: "Montant Attachements Payés",
  readyToPayAttachments: "Montant Attachements Situation Mensuelle",
  validatedAttachments: "Montant Attachements Validés",
  allAttachments: "Montant HT Attachements validés/situation mensuelle/payés",
  availableDC4: "Disponible sur DC4",
  availableDC4WithRad: "Disponible sur DC4 avec RAD",
};

const deposits = {
  depositAmount: "Deposit amount",
  depositUsed: "Amount bond",
  amountLeft: "Amount left",
  reimbursementDate: "Reimbursement date",
  addDeposit: "Add a deposit",
};

const documents = {
  sendToSignature: "Send for signature",
  sendToValidation: "Send to validation",
  signersModalTitle: "Signers info",
  validatorSelectionModalTitle: "Validators selection",
  axioneSide: "Axione",
  contractorSide: "Contractor",
  signaturePosition: "Signature placement",
  anchorString: "Placement anchor",
  successfullySent: "Contract successfully sent for signature",
  failedSent: "An error occured when sending the documents for signature",
  positionHelper:
    "On manual mode signers place themselves their signatures on the document.\nOn automatic mode, the signature is placed on an anchor (text).\nThe placement anchors must have the same document background color to be invisible on the final document.",
  anchorStingsShouldBeDifferent: "The placement anchors must be different",
  documents: 'Documents'
};

const documentTypes = {
  documentTypeCreation: "Document Type Creation",
};

const markets = {
  nbAttachments: "Number of Applications for payment",
  currentBpu: "Current Rate card",
  marketCreation: "Creation of a contractor agreement",
  deactivate: 'Deactivate',
  activate: 'activate',

  columns: {
    agencyCode: "Name",
    edifice: "Name",
    contractNumber: "Contract number",
    dateEndOfContract: "Contract expiry date",
    eotpSt: "Contractor WBS",
    marketNumber: "SAP number",
    maxAmountDC4: "Max DC4 Amount (France)",
    paymentCondition: "Payment terms",
    paymentType: "Payment type (France)",
    thresholdAmount: "Threshold amount (France)",
    vatType: "VAT Category",
    activated: "Activated"
  },
  vatTypes: {
    basic: "20%",
    tenPercents: "10%",
    reverseCharge: "Reverse charge",
  },
  paymentTypes: {
    direct: "Direct payment",
    company: "Standard payment",
  },
};

const notifications = {
  refus: "You must justify the refusal.",
  min: "(5 characters minimum).",
};

const ods = {
  title: "Manual Cost allocation template",
  situationDate: "Payment Notice Date",
  attSubcontractor: "Application for Payment Reference",
  senderEotp: "Sender WBS Element",
  receiverEotp: "Receiver WBS Element",
  amount: "Amount excl. VAT",
  accountingNature: "Cost element",
};

const oscarSubProjects = {
  constructionBaseQuantities: "Quantities Construction GIS database",

  columns: {
    subProjectYear: "Sub-project's Year",
    subProjectCity: "Sub-project's Town",
    subprojectStatus: "Sub-project's status",
    arcgisName: "Arcgis Name",
    oscarSubProject: "OSCAR Sub-project",
    arcgisReference: "Arcgis Reference",
    oiCode: "Code OI (France)",
    marketType: "Project type",
    projectName: "Project Name",
    subProjectName: "Sub-project name",
    nroName: "POP Name",
    nroIpeId: "POP Name",
    pmIpeId: "FDH name",
    pmOfferId: "FDH number",
    pmAxioneId: "Project Reference",
    pmCity: "FDH's Town",
  },
};

const orders = {
  releaseSituation: "Rentention's Payment Notice",
  wfStatus: "Workflow status",
  displayAllPrestations: "Display all items of the Rate Card",
  totalPrestation: "Total amount",
  invoiced: "Previously Notified",
  releaseDate: "Retention release date",
  needMarket: "Needs a contractor agreement",
  orderCreation: "Creation of an order",

  columns: {
    edifice: "Internal contract reference",
    contractNumber: "Contract Number",
    complement: "Details",
    clientMarket: "Client Agreement (France)",
    completionDate: "Completion date",
    deliveryDuration: "Delivery duration",
    bdcCustomer: "Client order (France)",
    managedBy: "Managed by",
    mainClient: "Main client (France)",
    orderManagedBy: "Managed by",
    sstMarket: "Contractor Agreement",
    deliveryDate: "Network Acceptance Document",
  },
};

const orderTemplates = {
  switchAll: "Tout ouvrir/fermer",
  templateCreation: "Order Template Creation",
};

const paymentGuarantees = {
  fieldNamesMustBeInList:
    "The names of the form fields must be in the following list:",
  addGuarantee: "Add a Payment Guarantee",
};

const prestations = {
  needCategory: "Needs a category",
  prestationCreation: "Rate card item creation",

  columns: {
    unitPrice: "Unit price",
    itemCode: "Item code",
  },
};
const prestationReferences = {
  prestationReferenceCreation: "Reference of Rate card item creation",
};

const prestationTypes = {
  prestationTypeCreation: "Type of Rate card item creation",
};

const projects = {
  nbOrdersValidated: "Orders validated and/or closed",
  nbAttachmentsPaid: "Applications for payment paid",
  sumOrdersValidated: "Cumulative amount of Orders validated and/or closed",
  sumAttachmentsPaid: "Cumulative amount paid",
  imputationCreation: "Creation of a Cost allocation",
  editImputation: "Edit a cost allocation",
  addUserToProject: "Add a utilisateur to a projet",
  monthlyGenerationDay: "Payment Notice issuance date",
  shareFiles: "Partager les attachements dans Sharepoint",

  projectCreation: "Project Creation",
  directorAddress: "Mailing address",
  columns: {
    manager: "Project Director",
  },
};

const projectTypes = {
  projectTypeCreation: "Project Type creation",
};

const quantitatives = {
  quantityOrdered: "Quantity ordered",
  cumulatedQuantity: "Cumulated quantity delivered",
  orderedAmount: "Ordered amount",
  cumulativeAmount: "Cumulated amount ordered",
};

const roles = {
  roleCreation: "Role creation",
  additionalPermissions: "You can set additional permissions on this role.",
  visibilities: {
    all: "Access to all elements of the database",
    projects: "Access limited to user's projets",
    managerProjects: "Access limited to the projects of the user's manager",
    projectsOffices: "Access limited to user's projets and user's offices",
  },
};

const situations = {
  markPaid: "Mark paid",
  cancelPayment: "Cancel payment",
  attachmentNo: "Application for Payment number",
  releasedAmount: "Retention release amount (excl. VAT)",
  sapReference: "SAP Contract number",
  theoreticalPaymentDate: "Final date for payment",
  currentMonthAmount: "Monthly amount (excl. VAT)",
  rgAmount: "Retention amount (excl. VAT)",
  bondAmount: "Bond amount",
  withheldAmount: "Withheld amount",
  amountWoRg: "Amount without Retention (excl. VAT)",
  totalAmountWoVat: "Total amount (excl. VAT)",
  vatAmount: "VAT amount",
  reverseChargeAmount: "Reverse charge VAT amount",
  totalAmount: "Total amount (incl. VAT)",
  releaseSituation: "Payment notice with released retention",
  releaseDate: "Retention release date",
  totalWoVat: "Total (excl. VAT)",
  totalWithVat: "Total (incl. VAT)",
  situationCreation: "Create a Payment Notice",
  projectSelection: "Projects selection",
};

const units = {
  unitCreation: "Creation of unit",
};

const users = {
  changePerimeter: "Change scope",
  projectType: "Project type",
  secondaryProfiles: "Additional profiles",
  assignPerimeterToUser: "Assign scope to another user",
  authorizationAmount: "Limit authorisation",
  mainProfile: "Main Profile",
  addUser: "User creation",
  ldapCommonName: "LDAP CommonName",
  columns: {
    manager: "Line manager",
  },
};

const warranties = {
  title: "Retention Monitoring",
  depositType: "Retention/Bond",
  attachementAmount: "Validate Applications for payment amount",
  totalRetention: "Retention amount",
  releasedAmount: "Retention released amount",
  remainingAmount: "Retention amount to be released",
  bondAmount: "Retention bond amount",
  withheldAmount: "Retention withheld amount",

  orderReference: "Référence commande",
  orderEndDate: "Order end date",
  deliveryDate: "Network Acceptance Document",
  releaseDate: "Retention release date",
  attAmount: "Applications for Payment total amount",
  attAmountHelp: "Validated + Noticed + Paid",
  rgHelp: "Retention",
  bondRg: "Retention bond",
  bondRgHelp: "Amount used on a deposit",
  withheldRg: "Retention withheld",
  rgReleased: "Retention released",
  rgReleasedHelp: "Retentions released in a Payement notice",
  rgToRelease: "Retention to be released",
  rgToReleaseHelp: "Retenions left to be released in a Payement notice",
  retentionTypes: {
    basic: "Retention",
    withThreshold: "Retention with threshold",
    nonApplicable: "Non applicable",
  },
};

const errors = {
  many2OneField: {
    selectItem: 'The field {field} is mandatory'
  }
}

const account = {
  myAccount: 'Mon compte',
  delegation: {
    delegations: 'My delegations',
    noDelegation: 'You didn\'t set-up any rights delegation',
    delegationCreation: 'Create a delegation',
    delegateTo: 'Delegate to',
    receivedDelegations: 'Received delegations',
    noReceivedDelegation: 'No delegation received',
    delegateFrom: 'Delegation from',
    userToDelegate: 'User to delegate',
    userDelegated: 'User receiving the delegation',
    userDelegations: 'User\'s delegations',
    userNoDelegation: 'The user didn\'t setup any rights delegation.',
    userNoReceivedDelegation: 'The user didn\'t receiving any delegations.',
    byDelegationOf: 'by delegation of'
  }
}

const contract = {
  contractNumber: 'Contract number',
  parentContract: 'Parent Contract',
  contractType: 'Contract type',
  signatureDate: 'Signature date',
  startDate: 'Start date',
  endDate: 'Expiration date',
  duration: 'Duration',
  terminationDate: 'Termination date',
  referent: 'Referent',
  adminReferent: 'Admin referent',
  active: 'Active',
  contractCreation: 'Contract creation',
  contractChild: 'Childs contracts',
  noChildContracts: 'No child contracts.'
}

const popup = {
  tabs: {
    content: 'Popup content',
    users: 'Users',
    information: 'Information'
  },
  addUsers: "Add users",
  removeUsers: "Delete users",
  readedAt: 'Read',
  closedAt: 'Closed'
}

export default {
  agencies,
  attachments,
  bpus,
  bpuTemplates,
  categories,
  common,
  companies,
  contractTemplates,
  dc4,
  deposits,
  documents,
  documentTypes,
  entities,
  expressions,
  home,
  login,
  markets,
  notifications,
  ods,
  orders,
  orderTemplates,
  oscarSubProjects,
  paymentGuarantees,
  prestations,
  prestationReferences,
  prestationTypes,
  projects,
  projectTypes,
  quantitatives,
  roles,
  situations,
  units,
  users,
  warranties,
  errors,
  account,
  contract,
  popup
};
