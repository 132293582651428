import Vue from "vue";
import axios from "axios";
import moment from "moment";
import { Workflows as WORKFLOWS } from "../constants/Workflow";
import { i18n } from "../i18n";

const WorkflowHook = {
  preTransitionHooks: {},
  postTransitionHooks: {},
  addPreHook(transitions, callback) {
    if(!Array.isArray(transitions)) {
      transitions = [transitions];
    }

    transitions.forEach(transition => {
      if (!this.preTransitionHooks[transition]) {
        this.preTransitionHooks[transition] = [];
      }
      this.preTransitionHooks[transition].push(callback)
    });
  },
  addPostHook(transitions, callback) {
    if(!Array.isArray(transitions)) {
      transitions = [transitions];
    }

    transitions.forEach(transition => {
      if (!this.postTransitionHooks[transition]) {
        this.postTransitionHooks[transition] = [];
      }

      this.postTransitionHooks[transition].push(callback)
    });
  },
  executePreHooks(transition, data, onSuccess) {
    if (!this.preTransitionHooks[transition]) {
      onSuccess();
      return;
    }

    this.executeHooks(this.preTransitionHooks[transition], data, onSuccess);
  },
  executeHooks(list, data, onSuccess) {
    const result = list.reduce(
      (promise, nextHook) => promise.then((d) => nextHook(d)),
      Promise.resolve(data)
    );
    result
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  executePostHooks(transition, data) {
    if (!this.postTransitionHooks[transition]) {
      return;
    }

    this.executeHooks(this.postTransitionHooks[transition], data, () => {});
  },
};

/**
 * Hooks button refuse
 */

WorkflowHook.addPreHook(
  [
    WORKFLOWS.ATT.NODE.REFUSED,
    WORKFLOWS.OS.NODE.DRAFT
  ],
  (data) => {
    let onOk = "";
    const comment = data.comment;
    if (comment.length < 5) {
      return new Promise((res, rej) => {
        Vue.prototype.$awn.confirm(i18n.t("notifications.min"), onOk, false, {
          labels: {
            confirm: i18n.t("notifications.refus"),
          },
        });
        rej();
      });
    }
    return Promise.resolve(data);
  }
);

/**
 * Hooks registration
 */

WorkflowHook.addPreHook(WORKFLOWS.ATT.NODE.VALIDATED, (data) => {
  const attachement = data.entity;
  // If amount is equal to 0 AND current workflow is `att_validating` AND moving to `att_validated`
  if (
    !attachement.amount &&
    attachement.statusWF &&
    attachement.statusWF.node.nodeCode === WORKFLOWS.ATT.NODE.VALIDATING_AMOUNT
  ) {
    return new Promise((res, rej) => {
      Vue.prototype.$awn.confirm(
        "Attention votre attachement est à 0€. Êtes-vous sûr de vouloir le valider ?",
        () => res(data),
        () => rej()
      );
    });
  }
  // TOTAL ATT > TOTAL OS
  if (
    attachement.totalAttachements > attachement.totalOS &&
    attachement.statusWF &&
    attachement.statusWF.node.nodeCode === WORKFLOWS.ATT.NODE.VALIDATING_AMOUNT
  ) {
    return new Promise((res, rej) => {
      Vue.prototype.$awn.confirm(
        "Le montant total de tous les attachements dépasse celui de la commande. voulez-vous continuer ?",
        () => res(data),
        () => rej()
      );
    });
  }
  return Promise.resolve(data);
});

WorkflowHook.addPreHook(WORKFLOWS.ATT.NODE.VALIDATING_AMOUNT, (data) => {
  const attachement = data.entity;
  // TOTAL ATT > TOTAL OS
  if (
    attachement.totalAttachements > attachement.totalOS &&
    attachement.statusWF &&
    attachement.statusWF.node.nodeCode === WORKFLOWS.ATT.NODE.VALIDATING
  ) {
    return new Promise((res, rej) => {
      Vue.prototype.$awn.confirm(
        "Le montant total de tous les attachements dépasse celui de la commande. voulez-vous continuer ?",
        () => res(data),
        () => rej()
      );
    });
  }
  return Promise.resolve(data);
});

WorkflowHook.addPreHook([ WORKFLOWS.ATT.NODE.VALIDATING, WORKFLOWS.ATT.NODE.VALIDATING_SST ], (data) => {
  const attachement = data.entity;
  // If amount is equal to 0 AND current workflow is `att_draft` AND moving to `att_validating`
  if (
    !attachement.amount &&
    attachement.statusWF &&
    attachement.statusWF.node.nodeCode === WORKFLOWS.ATT.NODE.DRAFT
  ) {
    return new Promise((res, rej) => {
      Vue.prototype.$awn.confirm(
        "Attention votre attachement est à 0€. Êtes-vous sûr de vouloir l'envoyer en validation ?",
        () => res(data),
        () => rej()
      );
    });
  }
  // TOTAL ATT > TOTAL OS
  if (
    attachement.totalAttachements > attachement.totalOS &&
    attachement.statusWF &&
    attachement.statusWF.node.nodeCode === WORKFLOWS.ATT.NODE.DRAFT
  ) {
    return new Promise((res, rej) => {
      Vue.prototype.$awn.confirm(
        "Le montant total de tous les attachements dépasse celui de la commande. voulez-vous continuer ?",
        () => res(data),
        () => rej()
      );
    });
  }
  return Promise.resolve(data);
});

// When attachment is validated and another attachment has been added to the OS this month
WorkflowHook.addPreHook(WORKFLOWS.ATT.NODE.VALIDATED, (data) => {
  const attachement = data.entity;
  return axios.get(`/order/${attachement.order.id}`).then((response) => {
    const order = response.data;
    // If the order don't have lastAttachment
    // Or if the last attachment on the order is the one to be validated
    // Validate the ATT
    if (!order.lastAttachment || order.lastAttachment.id === attachement.id) {
      return data;
    }

    // If the attachment to be validated is different than the last on the order
    // Check if the last attachment has been created in the last 30 days
    // In that case, display the popup, otherwise validate the ATT
    const lastAttachmentDate = moment(order.lastAttachment.createdAt).diff(
      moment.now(),
      "days"
    );
    if (lastAttachmentDate < -30) {
      return Promise.resolve(data);
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$awn.confirm(
        "Attention, un autre attachement a été présenté sur cette commande ce mois-ci",
        () => resolve(data),
        () => reject()
      );
    });
  });
});

/**
 * Hook agency check
 */
WorkflowHook.addPreHook(
  [
    WORKFLOWS.OS.NODE.VALIDATING,
    WORKFLOWS.OS.NODE.VALIDATING_AMOUNT,
    WORKFLOWS.OS.NODE.VALIDATED,
    WORKFLOWS.ATT.NODE.VALIDATING_SST,
    WORKFLOWS.ATT.NODE.VALIDATING,
    WORKFLOWS.ATT.NODE.VALIDATING_AMOUNT,
    WORKFLOWS.ATT.NODE.VALIDATED,
  ],
  (data) => {
    const order = data.entity;
    const agency = order.agency;

    // Case if no agency is available on the order.
    if(!agency) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$awn.confirm(
          "Erreur: Aucun etablissement visible sur la commande !",
          () => resolve(data),
          () => reject()
        );
      });
    }

    //
    if (
      agency.synchronize &&
      !(agency.documentsCompleted && agency.status === 1)
    ) {
      return new Promise((res, rej) => {
        Vue.prototype.$awn.confirm(
          i18n.t('agencies.anomalies'),
          () => res(data),
          () => rej()
        );
      });
    }

    return Promise.resolve(data);
  }
);

export default WorkflowHook;
