export const RoleOptions = Object.freeze({
  DOCUMENT: {
    CAN_SEND_FOR_SIGNATURE: 'documents.can_send_for_signature',
  },
  OS: {
    DELETE_AFTER_VALIDATION: 'serviceorders.deletion_after_validation',
    SST_VALIDATION_IN_DASHBOARD: 'serviceorders.display_sst_validation_in_dashboard',
    EDIT_FINANCIAL_INFO: 'serviceorders.edit_financial_info',
    DISPLAYEDIT_FINANCIAL_INFO: 'serviceorders.display_order_download_button',
  },
  USERS: {
    SEE_DEFAULT: 'users.can_see_default',
    CAN_DELEGATE: 'users.can_delegate',
    CAN_ADMIN_DELEGATE: 'users.can_admin_delegate'
  },
  ATTACHMENTS: {
    LOCKED_AFTER_VALIDATION: 'attachements.cannot_edit_after_validation',
  },
  MONTHLY_STATEMENTS: {
    DANGEROUSLY_REVERT: 'monthly_statements.can_dangerously_revert_paid',
  },
  MARKETS: {
    DEACTIVATE_MARKET: 'subcontractor_markets.deactivate_markets'
  }
});

export const Entities = [];
