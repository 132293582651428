<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Document">
        <h1 class="title">{{$t('documents.documents')}}</h1>
      </page-header>

      <datatable :fetch="fetchDocuments"
                 ref="listDocuments">
        <div slot="filename"
             slot-scope="{ item }"
             :title="$t('expressions.fileName')"
             :sortable="true">
          <span>{{ item.filename }}</span>
        </div>
        <div slot="type"
             slot-scope="{ item }"
             :title="$t('common.type')"
             :sortable="true">
          <span>{{ item.type && item.type.name }}</span>
        </div>
        <div slot="createdAt"
             slot-scope="{ item }"
             :title="$t('common.date')"
             :sortable="true">
          <span>{{ item.createdAt | reverseDate }}</span>
        </div>
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/document/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  name: 'document-list',
  data () {
    return {
    };
  },
  methods: {
    fetchDocuments(paginate)
    {
      return this.$api.fetchContractDocuments(paginate);
    }
  }
};
</script>

<style scoped>
</style>

