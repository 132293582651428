"use strict";

import axios from "axios";
import moment from "moment";
import VeeValidate, { Validator } from "vee-validate";
import en from "vee-validate/dist/locale/en";
import fr from "vee-validate/dist/locale/fr";
import Vue from "vue";
import VueAWN from "vue-awesome-notifications";
import Nl2br from "vue-nl2br";
import App from "./App";
import { currency, i18n, shortLocale } from "./i18n";
// import './mocks';
import router from "./router";
import store from "./store";

import AgencyDisplay from "./components/displays/AgencyDisplay";
import Breadcrumb from "./components/Breadcrumb";
import Datatable from "./components/Datatable";
import DocumentManager from "./components/DocumentManager";
import Field from "./components/Field";
import CheckboxField from "./components/fields/CheckboxField";
import DateField from "./components/fields/DateField";
import Many2OneField from "./components/fields/Many2OneField";
import NumericField from "./components/fields/NumericField";
import SelectorField from "./components/fields/SelectorField";
import SelectorFieldMod from "./components/fields/SelectorFieldMod";
import TextareaField from "./components/fields/TextareaField";
import TextField from "./components/fields/TextField";
import SIRETField from "./components/fields/SIRETField";
import FlexiTable from "./components/FlexiTable";
import Modal from "./components/Modal";
import NotificationContainer from "./components/notifications/NotificationContainer";
import PageHeader from "./components/PageHeader";
import PanelHeader from "./components/PanelHeader";
import RestrictedLink from "./components/RestrictedLink";
import SelectAll from "./components/SelectAll";
import WorkflowChart from "./components/WorkflowChart";
import WorkflowHistory from "./components/WorkflowHistory";
import PopupEditor from "./components/PopupEditor";
import PopupComponent from "./components/PopupComponent";

import Api from "./lib/Api";
import WorkflowHook from "./lib/WorkflowHook";

import { EntitiesName } from "./constants/Entity";
import { RoleOptions } from "./constants/Role";

import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";

require("moment/locale/fr");

Vue.component("agency-display", AgencyDisplay);
Vue.component("nl2br", Nl2br);
Vue.component("modal", Modal);
Vue.component("datatable", Datatable);
Vue.component("page-header", PageHeader);
Vue.component("panel-header", PanelHeader);
Vue.component("field", Field);
Vue.component("text-field", TextField);
Vue.component("siret-field", SIRETField);
Vue.component("numeric-field", NumericField);
Vue.component("textarea-field", TextareaField);
Vue.component("checkbox-field", CheckboxField);
Vue.component("date-field", DateField);
Vue.component("selector-field", SelectorField);
Vue.component("selector-field-mod", SelectorFieldMod);
Vue.component("many2one-field", Many2OneField);
Vue.component("NotificationContainer", NotificationContainer);
Vue.component("workflow-history", WorkflowHistory);
Vue.component("workflow-chart", WorkflowChart);
Vue.component("flexi-table", FlexiTable);
Vue.component("breadcrumb", Breadcrumb);
Vue.component("document-manager", DocumentManager);
Vue.component("select-all", SelectAll);
Vue.component("restricted-link", RestrictedLink);
Vue.component("popup-editor", PopupEditor);
Vue.component("popup-component", PopupComponent);

require("./assets/sass/main.scss");
require("vue-awesome-notifications/dist/styles/style.css");

Vue.config.productionTip = false;

/**
 * ERROR MANAGEMENT
 */
const APP_URL = process.env.VUE_APP_APP_URL || "https://isf.axione.fr";
const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN || null;
if (SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [APP_URL, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE || 1.0,
    release: process.env.VUE_APP_VERSION,
    environment: process.env.NODE_ENV,
    beforeSend: (event) => {
      console.log(event);
      const authAttempt = store.getters["auth/getAttempt"];
      const uid = store.getters["auth/getUid"];
      event.user = {
        uid,
        authAttempt,
      };
      return event;
    },
  });
}

/**
 * Dialog
 */

const awnOptions = {
  labels: {
    confirm: "",
  },
};
Vue.use(VueAWN, awnOptions);

/**
 * Axios configuration
 */
axios.defaults.baseURL = process.env.VUE_APP_API_URL || "/api";

axios.interceptors.request.use(
  (config) => {
    store.dispatch("states/setLoading", true);
    return config;
  },
  (error) => {
    // eslint-disable-next-line
    console.log("Request error: ");

    // eslint-disable-next-line
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch("states/setLoading", false);
    return response;
  },
  (error) => {
    store.dispatch("states/setLoading", false);
    if (error.response) {
      // NOTE: Keep this line for Sentry reporting
      // eslint-disable-next-line
      console.log("Response code: " + error.response.status);

      // eslint-disable-next-line
      // console.log(error.response.data)

      // Skip error handling for the polling of notification.
      if (
        error.response.data.path &&
        error.response.data.path.includes("api/notification/")
      ) {
        return Promise.reject("Erreur de connexion internet !");
      }
      switch (error.response.status) {
        case 500:
          // eslint-disable-next-line
          alert("Une erreur est survenue");
          break;
        case 400: {
          // Validation errors
          const err = error.response.data;

          if (err.alert) {
            // eslint-disable-next-line
            alert(err.error);
          }
          // eslint-disable-next-line
          console.log(err);
          break;
        }
        case 422:
          // let errors = _.values(error.response.data.errors);
          // Vue.prototype.$awn.warning(_.first(errors)[0]);
          break;

        case 403:
          store.dispatch("auth/logout");
          router.replace({ path: "/login" });
          break;
        case 401:
          store.dispatch("auth/logout");
          router.replace({ path: "/login" });
          return Promise.reject("Session expirée");
        default:
          // eslint-disable-next-line
          alert(
            `Une erreur est survenue. ${error.response.data.path} a répondu avec le code d'erreur ${error.response.status} ${error.response.data.error} "${error.response.data.message}"`
          );
          break;
      }
    }

    return Promise.reject(error);
  }
);

/**
 * MomentJS
 */
moment.locale(shortLocale);
Vue.filter("fromNow", (value) => moment(value).fromNow());
Vue.filter("datetime", (value) =>
  value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : ""
);
Vue.filter("date", (value) =>
  value ? moment(value).format("YYYY-MM-DD") : ""
);
Vue.filter("reverseDate", (value) =>
  value ? moment(value).format("DD/MM/YYYY") : ""
);

Vue.filter("twoSF", (value) => value.toFixed(2));
Vue.filter("priceEUR", (value) =>
  new Intl.NumberFormat(shortLocale, {
    style: "currency",
    currency: currency,
  }).format(value)
);
Vue.filter("number", (value, digits = 0) =>
  new Intl.NumberFormat(shortLocale, {
    style: "decimal",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
);

/**
 * Globally add api methods
 */
Vue.prototype.$api = Api;
Vue.prototype.$workflowHook = WorkflowHook;
Vue.prototype.$entitiesName = EntitiesName;
Vue.prototype.$roleOptions = RoleOptions;

/**
 * Validations
 */
Vue.use(VeeValidate, { validity: true });
Validator.localize(shortLocale, shortLocale === "fr" ? fr : en);
Validator.extend("mustBeAfter", {
  getMessage() {
    return i18n.t("expressions.mustBeAfter");
  },
  validate(value, ctrl) {
    const ctrlDate = new Date();
    ctrlDate.setTime(Date.parse(ctrl[0]));
    const dateField = new Date();
    dateField.setTime(Date.parse(value));
    return dateField > ctrlDate;
  },
});

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.$router = router;
