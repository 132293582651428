<template>
  <field :cy-data="cyData"
         :edit="edit"
         :inline="inline"
         :label="label"
         :isCheckbox="true"
         :isChecked="isChecked">
    <input :id="id"
           type="checkbox"
           class="switch is-rounded is-info"
           :checked="isChecked"
           @change="change()"
           :cy-data="cyDataString">
    <label :for="id" :cy-data="`label-${cyDataString}`"></label>
  </field>
</template>

<script>
import ui from 'uniqid';

export default {
  name: 'checkbox-field',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    checked: null,
    cyData: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      val: this.value,
      id: ui(),
      check: this.checked,
    };
  },
  watch: {
    value (val) {
      this.val = val;
    },
    checked (val) {
      this.check = val;
    },
  },
  computed: {
    isChecked () {
      if (typeof this.check === 'object') {
        return this.check.includes(this.val);
      }
      return this.check;
    },
    cyDataString () {
      const fieldLabel = this.cyData ? this.cyData : this.label;
      if (!fieldLabel) { return ''; }
      return `input-${fieldLabel.replaceAll(/[ ']/g, '')}`;
    },
  },
  methods: {
    change () {
      let checked = this.check;
      if (typeof checked === 'object') {
        const foundIndex = checked.indexOf(this.val);
        if (foundIndex !== -1) {
          checked.splice(foundIndex, 1);
        } else {
          checked.push(this.val);
        }
      } else {
        checked = !checked;
      }
      this.$emit('change', checked);
    },
  },
};
</script>

<style lang='scss' scoped>
</style>

