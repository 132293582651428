<template>
  <modal ref="selectionModal"
         :title="this.$t('attachments.prestationSelection')">
    <datatable single-select
               :fetch="fetch"
               @input="emitInput"
               :columns="prestationModalColumns"
               :size="15"
               selector></datatable>
  </modal>
</template>

<script>
export default {
  name: 'prestation-selector-modal',
  props: {
    value: {},
    fetch: Function,
  },
  data () {
    return {
      prestationModalColumns: {
        'category.parent.name': {
          title: this.$t('common.category'),
          filter: null,
        },
        'category.name': {
          title: this.$t('common.subcategory'),
          filter: '',
        },
        'prestation.reference': {
          title: this.$t('common.reference'),
          filter: '',
        },
        'prestation.code': {
          title: this.$t('common.code'),
          filter: '',
        },
        'prestation.description': {
          title: this.$t('common.designation'),
          filter: '',
        },
        'unit.name': {
          title: this.$t('common.unit'),
          filter: '',
        },
        cost: {
          title: this.$t('prestations.columns.unitPrice'),
          filter: '',
        },
      }
      ,
    };
  },
  methods: {
    open () {
      this.$refs.selectionModal.open();
    },
    emitInput (item) {
      this.$emit('input', item);
      this.$refs.selectionModal.close();
    },
  },
};
</script>
