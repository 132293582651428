import store from '@/store';
import Vue from 'vue';
import Router from 'vue-router';
import { i18n } from '../i18n';

import AgenciesList from '@/pages/agencies/AgenciesList';
import Agency from '@/pages/agencies/Agency';
import Attachement from '@/pages/attachements/Attachement';
import AttachementList from '@/pages/attachements/AttachementList';
import Bpu from '@/pages/bpus/Bpu';
import BpuList from '@/pages/bpus/BpuList';
import BpuTemplate from '@/pages/bpus/BpuTemplate';
import BpuTemplateList from '@/pages/bpus/BpuTemplateList';
import Category from '@/pages/categories/Category';
import CategoryList from '@/pages/categories/CategoryList';
import CompaniesList from '@/pages/companies/CompaniesList';
import Company from '@/pages/companies/Company';
// import Dsp from '@/pages/dsps/Dsp';
// import DspList from '@/pages/dsps/DspList';
import Home from '@/pages/Home';
import Login from '@/pages/Login';
import Market from '@/pages/markets/Market';
import MarketList from '@/pages/markets/MarketList';
import NotificationList from '@/pages/notifications/NotificationList';
import Manage from '@/pages/account/Manage'
// import Nro from '@/pages/nros/Nro';
// import NroList from '@/pages/nros/NroList';
import OscarSubProject from '@/pages/oscarSubProjects/OscarSubProject';
import OscarSubProjectList from '@/pages/oscarSubProjects/OscarSubProjectList';
import OsTemplate from '@/pages/osTemplates/OsTemplate';
import OsTemplateList from '@/pages/osTemplates/OsTemplateList';
// import Pm from '@/pages/pms/Pm';
// import PmList from '@/pages/pms/PmList';
import Dc4 from '@/pages/budget/dc4';
import Quantitatives from '@/pages/budget/quantitatives';
import Warranties from '@/pages/budget/warranties';
import ContractTemplates from '@/pages/contractTemplates/ContractTemplateList';
import DepositList from '@/pages/deposits/DepositList';
import DocumentType from '@/pages/documentTypes/DocumentType';
import DocumentTypeList from '@/pages/documentTypes/DocumentTypeList';
import ODList from '@/pages/od/OdList';
import PaymentGuarantees from '@/pages/paymentGuarantees/PaymentGuaranteeList';
import Prestation from '@/pages/prestations/Prestation';
import PrestationList from '@/pages/prestations/PrestationList';
import PrestationType from '@/pages/prestationTypes/PrestationType';
import PrestationTypesList from '@/pages/prestationTypes/PrestationTypesList';
import PrestationReference from '@/pages/prestationReferences/PrestationReference';
import PrestationReferencesList from '@/pages/prestationReferences/PrestationReferencesList';
import Project from '@/pages/projects/Project';
import ProjectList from '@/pages/projects/ProjectList';
import ProjectType from '@/pages/projectTypes/ProjectType';
import ProjectTypeList from '@/pages/projectTypes/ProjectTypeList';
import Roles from '@/pages/roles/Roles';
import RolesList from '@/pages/roles/RolesList';
import ServiceOrder from '@/pages/serviceOrders/ServiceOrder';
import ServiceOrderList from '@/pages/serviceOrders/ServiceOrderList';
import Situation from '@/pages/situations/Situation';
import SituationList from '@/pages/situations/SituationList';
import Unit from '@/pages/units/Unit';
import UnitsList from '@/pages/units/UnitsList';
import User from '@/pages/users/User';
import UsersList from '@/pages/users/UsersList';
import WarrantyList from '@/pages/warranties/WarrantyList';
import Workflow from '@/pages/workflows/Workflow';
import WorkflowNode from '@/pages/workflows/WorkflowNode';
import ContractsList from '@/pages/contracts/ContractsList';
import Contract from '@/pages/contracts/Contract';
import PopupList from '@/pages/popups/PopupList'
import PopupView from '@/pages/popups/PopupView'
import DocumentsListView from '@/pages/documents/DocumentsList'
import Document from '@/pages/documents/Document'

import { EntitiesName } from '../constants/Entity';

Vue.use(Router);

const router = new Router({
  base: __dirname,
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/account-manage',
      name: 'AccountManage',
      component: Manage,
    },
    {
      path: '/sst/companies',
      name: 'CompaniesList',
      component: CompaniesList,
      meta: { entityName: EntitiesName.Company },
    },
    {
      path: '/sst/company/:id',
      name: 'Company',
      component: Company,
      props: true,
      meta: { entityName: EntitiesName.Company },
    },
    {
      path: '/sst/agencies',
      name: 'AgenciesList',
      component: AgenciesList,
      meta: { entityName: EntitiesName.Agency },
    },
    {
      path: '/sst/agency/:id',
      name: 'Agency',
      component: Agency,
      props: true,
      meta: { entityName: EntitiesName.Agency },
    },
    {
      path: '/sst/contracts',
      name: 'Contracts',
      component: ContractsList,
      props: true,
      meta: { entityName: EntitiesName.Contract },
    },
    {
      path: '/sst/contract/:id',
      name: 'Contract',
      component: Contract,
      props: true,
      meta: { entityName: EntitiesName.Contract },
    },
    {
      path: '/sst/markets',
      name: 'MarketList',
      component: MarketList,
      meta: { entityName: EntitiesName.SubcontractorMarket },
    },
    {
      path: '/sst/market/:id',
      name: 'Market',
      component: Market,
      props: true,
      meta: { entityName: EntitiesName.SubcontractorMarket },
    },
    {
      path: '/sst/orders',
      name: 'ServiceOrderList',
      component: ServiceOrderList,
      meta: { entityName: EntitiesName.Order },
    },
    {
      path: '/sst/order/:id',
      name: 'ServiceOrder',
      component: ServiceOrder,
      props: true,
      meta: { entityName: EntitiesName.Order },
    },
    {
      path: '/sst/attachements',
      name: 'AttachementList',
      component: AttachementList,
      meta: { entityName: EntitiesName.Attachement },
    },
    {
      path: '/sst/attachement/:id',
      name: 'Attachement',
      component: Attachement,
      props: true,
      meta: { entityName: EntitiesName.Attachement },
    },
    {
      path: '/sst/situations',
      name: 'SituationList',
      component: SituationList,
      meta: { entityName: EntitiesName.MonthlyStatement },
    },
    {
      path: '/sst/situation/:id',
      name: 'Situation',
      component: Situation,
      props: true,
      meta: { entityName: EntitiesName.MonthlyStatement },
    },
    {
      path: '/sst/bpu',
      name: 'BpuList',
      component: BpuList,
      meta: { entityName: EntitiesName.BpuSst },
    },
    {
      path: '/sst/bpu/:id',
      name: 'BPU',
      component: Bpu,
      props: true,
      meta: { entityName: EntitiesName.BpuSst },
    },
    {
      path: '/sst/bputemplates/',
      name: 'BpuTemplatesList',
      component: BpuTemplateList,
      meta: { entityName: EntitiesName.BpuTemplate },
    },
    {
      path: '/sst/bputemplate/:id',
      name: 'BPUTemplate',
      component: BpuTemplate,
      props: true,
      meta: { entityName: EntitiesName.BpuTemplate },
    },
    {
      path: '/os/warranties',
      name: 'WarrantyList',
      component: WarrantyList,
    },
    {
      path: '/sst/deposits',
      name: 'DepositList',
      component: DepositList,
    },
    {
      path: '/categories',
      name: 'CategoryList',
      component: CategoryList,
      meta: { entityName: EntitiesName.Category },
    },
    {
      path: '/category/:id',
      name: 'Category',
      component: Category,
      props: true,
      meta: { entityName: EntitiesName.Category },
    },
    {
      path: '/users',
      name: 'UsersList',
      component: UsersList,
      meta: { entityName: EntitiesName.User },
    },
    {
      path: '/user/:id',
      name: 'User',
      component: User,
      props: true,
      meta: { entityName: EntitiesName.User },
    },
    {
      path: '/roles',
      name: 'RolesList',
      component: RolesList,
      meta: { entityName: EntitiesName.Role },
    },
    {
      path: '/role/:id',
      name: 'Role',
      component: Roles,
      props: true,
      meta: { entityName: EntitiesName.Role },
    },
    {
      path: '/oscarsubprojects',
      name: 'OscarSubProjectList',
      component: OscarSubProjectList,
      meta: { entityName: EntitiesName.OscarSubProject },
    },
    {
      path: '/oscarsubproject/:id',
      name: 'OscarSubProject',
      component: OscarSubProject,
      props: true,
      meta: { entityName: EntitiesName.OscarSubProject },
    },
    {
      path: '/os/templates',
      name: 'OsTemplateList',
      component: OsTemplateList,
      meta: { entityName: EntitiesName.OsTemplate },
    },
    {
      path: '/os/template/:id',
      name: 'OsTemplate',
      component: OsTemplate,
      props: true,
      meta: { entityName: EntitiesName.OsTemplate },
    },
    {
      path: '/prestations',
      name: 'PrestationList',
      component: PrestationList,
      meta: { entityName: EntitiesName.Prestation },
    },
    {
      path: '/prestation/:id',
      name: 'Prestation',
      component: Prestation,
      props: true,
      meta: { entityName: EntitiesName.Prestation },
    },
    {
      path: '/projects',
      name: 'ProjectList',
      component: ProjectList,
      meta: { entityName: EntitiesName.Project },
    },
    {
      path: '/project/:id',
      name: 'Project',
      component: Project,
      props: true,
      meta: { entityName: EntitiesName.Project },
    },
    {
      path: '/wf/:id',
      name: 'Workflow',
      component: Workflow,
      props: true,
    },
    {
      path: '/node/:id',
      name: 'WorkflowNode',
      component: WorkflowNode,
      props: true,
    },
    {
      path: '/documenttypes',
      name: 'DocumentTypesList',
      component: DocumentTypeList,
      meta: { entityName: EntitiesName.DocumentType },
    },
    {
      path: '/documenttype/:id',
      name: 'DocumentType',
      component: DocumentType,
      props: true,
      meta: { entityName: EntitiesName.DocumentType },
    },
    {
      path: '/projecttypes',
      name: 'ProjectTypesList',
      component: ProjectTypeList,
      meta: { entityName: EntitiesName.ProjectType },
    },
    {
      path: '/projecttype/:id',
      name: 'ProjectType',
      component: ProjectType,
      props: true,
      meta: { entityName: EntitiesName.ProjectType },
    },
    {
      path: '/unit/:id',
      name: 'Unit',
      component: Unit,
      props: true,
      meta: { entityName: EntitiesName.Unit },
    },
    {
      path: '/units',
      name: 'Units',
      component: UnitsList,
      props: true,
      meta: { entityName: EntitiesName.Unit },
    },
    {
      path: '/prestationtype/:id',
      name: 'PrestationType',
      component: PrestationType,
      props: true,
      meta: { entityName: EntitiesName.PrestationType },
    },
    {
      path: '/prestationtypes',
      name: 'PrestationTypes',
      component: PrestationTypesList,
      props: true,
      meta: { entityName: EntitiesName.PrestationType },
    },
    {
      path: '/prestationreference/:id',
      name: 'PrestationReference',
      component: PrestationReference,
      props: true,
      meta: { entityName: EntitiesName.PrestationReference },
    },
    {
      path: '/prestationreferences',
      name: 'PrestationReferences',
      component: PrestationReferencesList,
      props: true,
      meta: { entityName: EntitiesName.PrestationReference },
    },
    {
      path: '/budget/od',
      name: 'OD',
      component: ODList,
      props: true,
      meta: { entityName: EntitiesName.Od },
    },
    {
      path: '/budget/dc4',
      name: 'dc4',
      component: Dc4,
      meta: { entityName: EntitiesName.Dc4 },
    },
    {
      path: '/budget/quantitatives',
      name: 'quantitatifs',
      component: Quantitatives,
      meta: { entityName: EntitiesName.quantitative },
    },
    {
      path: '/budget/warranties',
      name: 'Warranties',
      component: Warranties,
      meta: { entityName: EntitiesName.Warranties },
    },
    {
      path: '/paymentguarantees',
      name: 'PaymentGuarantees',
      component: PaymentGuarantees,
      meta: { entityName: EntitiesName.PaymentGuarantees },
    },
    {
      path: '/contracttemplates',
      name: 'ContractTemplates',
      component: ContractTemplates,
      meta: { entityName: EntitiesName.ContractTemplate },
    },
    {
      path: '/notifications',
      name: 'Notifications',
      component: NotificationList,
      //meta: { entityName: EntitiesName.Notification },
    },
    {
      path: '/popups-management',
      name: 'PopupsList',
      component: PopupList,
    },
    {
      path: '/popups-management/new',
      name: 'PopupView',
      props: false,
      component: PopupView,
    },
    {
      path: '/popups-management/:id',
      name: 'PopupView',
      props: true,
      component: PopupView,
    },
    {
      path: '/documents',
      name: 'DocumentsList',
      component: DocumentsListView,
      meta: { entityName: EntitiesName.DocumentType },
    },
    {
      path: '/document/:id',
      name: 'Documents',
      component: Document,
      props: true,
      meta: { entityName: EntitiesName.DocumentType },
    },
  ],
});

/**
 * Check if the user has the right to read an entity
 * Redirect to the previous route if the user can't
 */
router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.entityName)) {
    const entityName = to.matched.find(record => record.meta.entityName).meta.entityName;
    if (!store.getters['auth/getRights'](entityName).read) {
      // eslint-disable-next-line no-alert
      alert(i18n.t('expressions.accessNotGranted'));
      next(false);
      return;
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  /**
   * If the current mode is edition, we ask a confirmation before leaving the page.
   */
  if (store.getters['states/isEdition']) {
    router.app.$awn.confirm(
      i18n.t('expressions.leaveWithoutSaving'),
      () => {
        store.dispatch('states/setEdition', false);
        next();
      },
    );
    return;
  }

  if (to.path === '/logout') {
    store.dispatch('auth/logout');
  }

  if (to.path === '/login') {
    if (store.getters['auth/isAuthenticated']) {
      router.push('/');
    }
  }

  /**
   * If the user is not authenticated, we redirect to the login page.
   */
  if (to.path !== '/login') {
    // Check that active profile in the localstorage is the same as the current one
    const localStorageKey = store.getters['auth/getLocalStorageKey'];
    const activeProfile = store.getters['auth/getActiveProfile'];
    const profileStorage = JSON.parse(localStorage.getItem(localStorageKey));

    if (profileStorage && activeProfile.uid && profileStorage.activeProfile.uid !== activeProfile.uid) {
      router.app.$awn.confirm(
        i18n.t('expressions.keepProfile', [profileStorage.activeProfile.role.name]),
        () => {
          store.dispatch('auth/checkAuth');
          if (!store.getters['auth/isAuthenticated']) {
            router.push('/login');
            return;
          }
          next();
        },
      );
      return;
    }

    store.dispatch('auth/checkAuth');
    if (!store.getters['auth/isAuthenticated']) {
      router.push('/login');
      return;
    }
  }

  next();
});

export default router;
