<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.SubcontractorMarket"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <button v-if="market.canBeDeactivated && market.active && userRoleOptions.includes(
                $roleOptions.MARKETS.DEACTIVATE_MARKET
              )"  @click="deactivate" slot="actions" class="button is-warning">{{ $t("markets.deactivate") }}</button>

        <button v-if="!market.active && userRoleOptions.includes(
                $roleOptions.MARKETS.DEACTIVATE_MARKET
              )"  @click="activate" slot="actions" class="button is-warning">{{ $t("markets.activate") }}</button>
        <h1 class="title" cy-data="cy-market-title">
          <breadcrumb
            location="/sst/markets"
            text="Marché Sous-traitant"
          ></breadcrumb>
          <span data-test="sel-market-edifice">{{ market.edifice }}</span>
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                @click="currentTab = 0"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                @click="currentTab = 1"
              >
                <a>{{ $t("common.documents") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 2 }"
                @click="currentTab = 2"
              >
                <a>{{ $t("entities.situations") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 3 }"
                @click="currentTab = 3"
              >
                <a>{{ $t("entities.orders") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="panel-block no-border columns is-align-items-flex-start"
          v-if="currentTab === 0"
        >
          <div class="column">
            <text-field
              cy-data="edifice"
              :label="$t('markets.columns.agencyCode')"
              v-model="market.edifice"
              required
            ></text-field>
            <many2one-field
              :label="$t('markets.columns.contractNumber')"
              v-model="market.contract"
              :fetch="$api.fetchContracts"
              reference="contractNumber"
              @click="(item) => $router.push('/sst/contract/' + item.id)"
              entity="contract"
              :columns="contractColumn"
              :hasFilter="true"
              name="contract2one"
              cy-data="contract"
            ></many2one-field>
            <date-field
              :label="$t('markets.columns.dateEndOfContract')"
              v-model="market.dateEndOfContract"
              :edit="editMode"
            ></date-field>
            <many2one-field
              :label="$t('common.project')"
              v-model="market.project"
              :fetch="$api.fetchProjects"
              reference="name"
              @click="(item) => $router.push('/project/' + item.id)"
              entity="project"
              :columns="columnsProject"
              required
              :hasFilter="true"
              v-validate="{ required: true }"
              name="project2one"
              cy-data="project"
              :error="errors.has('project2one')"
            ></many2one-field>
            <many2one-field
              :label="$t('common.agency')"
              v-model="market.agency"
              :fetch="$api.fetchAgencies"
              reference="edifice"
              @click="(item) => $router.push('/sst/agency/' + item.id)"
              entity="agency"
              :columns="columnsAgency"
              required
              :hasFilter="true"
              v-validate="{ required: true }"
              name="agency2one"
              cy-data="agency"
              :error="errors.has('agency2one')"
            >
              <span slot="display" slot-scope="{ item }">
                <agency-display :value="item" />
              </span>
            </many2one-field>
            <text-field
              cy-data="sap"
              :label="$t('markets.columns.marketNumber')"
              v-model="market.sapReference"
            ></text-field>
            <numeric-field
              cy-data="dc4-amount"
              type="price"
              :label="$t('markets.columns.maxAmountDC4')"
              v-model="market.maxAmountDC4"
            ></numeric-field>
            <text-field
              cy-data="eotp"
              :label="$t('markets.columns.eotpSt')"
              v-model="market.eotpST"
            ></text-field>
            <selector-field
              cy-data="vat-type"
              :label="$t('markets.columns.vatType')"
              v-model="market.tva"
              :options="$api.fetchTVAType"
            />
            <checkbox-field
              :label="$t('markets.vatTypes.reverseCharge')"
              v-model="market.reverseCharge"
            />
            <selector-field
              :label="$t('common.retention')"
              v-model="market.depositType"
              :options="$api.fetchDepositType"
              cy-data="deposit-type"
            />
            <numeric-field
              v-if="
                market.depositType ===
                  $t('warranties.retentionTypes.withThreshold') &&
                globalLocale === 'fr_FR'
              "
              type="price"
              :label="$t('markets.columns.thresholdAmount')"
              v-model="market.threshold"
              unit="EUR"
              cy-data="threshold"
            ></numeric-field>
            <numeric-field
              v-if="
                market.depositType ===
                  $t('warranties.retentionTypes.withThreshold') ||
                (market.depositType === $t('warranties.retentionTypes.basic') &&
                  globalLocale === 'fr_FR')
              "
              type="percentage"
              :label="$t('markets.columns.rgRate')"
              v-model="market.rgRate"
              min="0"
              step="0.01"
              unit="%"
              cy-data="rg-rate"
            ></numeric-field>
            <selector-field
              :label="$t('markets.columns.paymentType')"
              v-model="market.paymentType"
              :options="$api.fetchPaymentType"
              cy-data="payment-type"
            />

            <selector-field
              :label="$t('markets.columns.paymentCondition')"
              v-model="market.paymentCondition"
              :options="$api.fetchPaymentConditionAsList"
              cy-data="payment-terms"
            />

            <many2one-field
              :label="$t('markets.currentBpu')"
              v-model="market.bpu"
              :fetch="(p) => $api.fetchBPUByMarket(id, p)"
              reference="name"
              @click="(item) => $router.push('/sst/bpu/' + item.id)"
              entity="bpu_sst"
              :columns="columnsBPU"
              :edit="editMode"
              :hasFilter="true"
              cy-data="bpu"
            ></many2one-field>
          </div>
          <div class="column">
            <Deposits
              :deposits="deposits"
              :add="addDeposit"
              :editable="
                market.depositType !==
                  $t('warranties.retentionTypes.nonApplicable') &&
                userRoleOptions.includes($roleOptions.OS.EDIT_FINANCIAL_INFO)
              "
              @added="fetchDeposits"
              @updated="fetchDeposits"
            />
          </div>
        </div>

        <div class="panel-block" v-if="currentTab === 1">
          <document-manager
            :objectName="$entitiesName.SubcontractorMarket"
            :objectId="id"
            :deletable="getRights($entitiesName.SubcontractorMarket).update"
            :contractTemplate="false"
            contractType="contractor_contract"
            :signature="false"
          />
        </div>

        <div class="panel-block" v-if="currentTab === 2">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("common.date") }}</th>
                <th>{{ $t("markets.nbAttachments") }}</th>
                <th>{{ $t("common.amount") }}</th>
                <th>{{ $t("common.status") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(statement, index) in statements" :key="index">
                <td>#{{ index + 1 }}</td>
                <td>{{ statement.createdAt | date }}</td>
                <td>{{ statement.attachementCount }}</td>
                <td>{{ statement.totalAmount }} €</td>
                <td>
                  <span v-if="statement.status">
                    {{ statement.status }}
                  </span>
                </td>
                <td>
                  <restricted-link
                    :entity="$entitiesName.MonthlyStatement"
                    :to="'/sst/situation/' + statement.id"
                    linkClass="button is-primary is-small"
                  >
                    <i class="fa fa-search"></i>
                  </restricted-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="panel-block" v-if="currentTab === 3">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("common.reference") }}</th>
                <th>{{ $t("common.name") }}</th>
                <th>{{ $t("common.project") }}</th>
                <th>{{ $t("common.imputation") }}</th>
                <th>{{ $t("common.bpu") }}</th>
                <th>{{ $t("common.total") }}</th>
                <th>{{ $t("common.status") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(serviceOrder, index) in serviceOrders" :key="index">
                <td>#{{ index + 1 }}</td>
                <td>{{ serviceOrder.reference }}</td>
                <td>{{ serviceOrder.name }}</td>
                <td>{{ market.project.name }}</td>
                <td>{{ serviceOrder.imputation.eotp }}</td>
                <td>
                  <span v-if="serviceOrder.bpu">{{
                    serviceOrder.bpu.name
                  }}</span>
                </td>
                <td class="has-text-right">
                  {{ serviceOrder.total | priceEUR }}
                </td>
                <td>
                  <span v-if="serviceOrder.statusWF">
                    <router-link
                      :to="'/node/' + serviceOrder.nodeId"
                      :title="serviceOrder.statusWF.createdAt | date"
                      >{{ serviceOrder.nodeName }}</router-link
                    >
                    ({{ serviceOrder.statusWF.createdAt | date }})
                  </span>
                </td>
                <td>
                  <restricted-link
                    entity="order"
                    :to="'/sst/order/' + serviceOrder.id"
                    linkClass="button is-primary is-small"
                  >
                    <i class="fa fa-search"></i>
                  </restricted-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Deposits from "../../components/Deposits.vue";
import { globalLocale } from "../../i18n";

export default {
  props: ["id"],
  components: {
    Deposits,
  },
  name: "market",
  data() {
    return {
      market: {
        agency: {},
        project: {},
      },
      statements: [],
      serviceOrders: [],
      currentTab: 0,
      columnsBPU: {
        id: this.$t("common.id"),
        name: this.$t("common.reference"),
      },
      columnsProject: {
        name: this.$t("common.name"),
        code: this.$t("common.code"),
        "type.name": this.$t("common.type"),
      },
      columnsAgency: {
        "company.name": this.$t("common.company"),
        edifice: this.$t("common.agency"),
        siret: this.$t("common.siret"),
        manager: this.$t("common.manager"),
      },
      contractColumn: {
        'agency.edifice': this.$t('common.agency'),
        contractNumber: this.$t("contract.contractNumber"),
        'documentType.name': this.$t('contract.documentType'),
        'referent.name': this.$t('contract.referent'),
        'adminReferent.name': this.$t('contract.adminReferent')
      },
      deposits: {},
      globalLocale: globalLocale,
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      userRoleOptions: "auth/getOptions",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/market/${this.id}`).then((response) => {
        this.$store.dispatch("states/setEdition", false);
        this.market = response.data;
      });

      axios.get(`/market/${this.id}/statements`).then((response) => {
        this.statements = response.data;
      });

      axios.get(`/market/${this.id}/serviceOrders`).then((response) => {
        this.serviceOrders = response.data;
      });

      this.fetchDeposits();
    },
    fetchDeposits() {
      axios.get(`/market/${this.id}/deposits`).then((res) => {
        res.data.forEach((deposit) => {
          if (deposit) {
            this.$set(this.deposits, deposit.id, {
              ...deposit,
              attachements: {},
            });
          }
        });
      });
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .put(`/market/${this.id}`, this.market)
            .then((response) => {
              if (response.data.success) {
                this.$store.dispatch("states/setEdition", false);
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData();
    },
    remove() {
      return this.$awn.confirm(this.$t("expressions.delete"), () =>
        axios
          .delete(`/market/${this.id}/delete`, this.market)
          .then((response) => {
            if (response.data.success) {
              this.$router.replace("/sst/markets");
            }
          })
      );
    },
    deactivate() {
      return this.$awn.confirm(this.$t("expressions.deactivate"), () =>
        axios
          .put(`/market/${this.id}/deactivate`, this.market)
          .then((response) => {
            this.market = response.data;
          })
      );
    },
    activate() {
      return this.$awn.confirm(this.$t("expressions.activate"), () =>
        axios
          .put(`/market/${this.id}/activate`, this.market)
          .then((response) => {
            this.market = response.data;
          })
      );
    },
    addDeposit(deposit) {
      return axios.post(`market/${this.id}/deposit`, deposit);
    },
  },
};
</script>

<style lang='scss' scoped>
.serviceOrder {
  display: inline-block;
  width: 100%;
}

.no-border {
  border: none;
  div {
    width: 100%;
    > span {
      width: 30%;
      display: inline-block;
    }
  }
}

.infos,
.documents,
.situation {
  display: inline-block;
  width: 47%;
}

.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.panel-block {
  align-items: flex-start;
}

.is-fullwidth {
  width: 100%;
}

.downloadButton {
  height: 36px !important;
}

.columns {
  margin: 0;
}
</style>
