<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.MonthlyStatement"
        :create="openCreateModal"
      >
        <h1 class="title">{{ $t("entities.situations") }}</h1>
        <template
          slot="actions"
          v-if="getRights($entitiesName.MonthlyStatement).create"
        >
          <button class="button is-success" @click="openProjectSelectionModal">
            {{ $t("common.generate") }}
          </button>
        </template>
      </page-header>

      <datatable
        :fetch="$api.fetchSituations"
        ref="situationList"
        :enableDownload="true"
        :columns="columnsSituation"
        objectName="monthlystatements"
        order="DESC"
        :bulkActions="monthlyStatementBtn"
      >
        <div
          slot="date"
          slot-scope="{ item }"
          :title="$t('common.date')"
          type="date"
          :sortable="true"
        >
          {{ item.date | reverseDate }}
        </div>

        <div
          slot="market"
          slot-scope="{ item: { market } }"
          :title="$t('common.contractorAgreement')"
          class="nowrap"
          :sortable="true"
          sortBy="market.edifice"
        >
          <div v-if="market">
            <restricted-link
              :entity="$entitiesName.SubcontractorMarket"
              :to="`/sst/market/${market.id}`"
            >
              {{ market.edifice }}
            </restricted-link>
          </div>
        </div>
        <div
          slot="sapReference"
          slot-scope="{ item: { market } }"
          :title="$t('markets.columns.marketNumber')"
          :sortable="true"
          sortBy="market.sapReference"
        >
          {{ market && market.sapReference }}
        </div>
        <div
          slot="agency.company"
          slot-scope="{ item: { agency } }"
          :title="$t('common.company')"
          :sortable="true"
          sortBy="market.agency.company.name"
        >
          <restricted-link
            :entity="$entitiesName.Company"
            v-if="agency"
            :to="`/sst/company/${agency.company.id}`"
          >{{ agency.company.name }}</restricted-link
          >
        </div>
        <div
          slot="agency"
          slot-scope="{ item: { agency } }"
          :title="$t('common.agency')"
          :sortable="true"
          sortBy="market.agency.edifice"
        >
          <restricted-link
            :entity="$entitiesName.Agency"
            v-if="agency"
            :to="`/sst/agency/${agency.id}`"
          >
            <agency-display :value="agency" />
          </restricted-link>
        </div>
        <div
          slot="market.project"
          slot-scope="{ item: { market } }"
          :title="$t('common.project')"
          :sortable="true"
          sortBy="market.project.name"
        >
          <restricted-link
            :entity="$entitiesName.Project"
            v-if="market && market.project"
            :to="`/project/${market.project.id}`"
          >{{ market.project.name }}</restricted-link
          >
        </div>

        <div
          slot="status"
          slot-scope="{ item }"
          :title="$t('common.status')"
          class="nowrap"
        >
          {{ item.status }}
        </div>

        <div
          slot="totalAmount"
          slot-scope="{ item }"
          :title="$t('situations.totalWoVat')"
          class="nowrap has-text-right"
        >
          {{ item.totalAmount | priceEUR }}
        </div>

        <div
          slot="rgamount"
          slot-scope="{ item }"
          :title="$t('situations.rgAmount')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.rgamount | priceEUR }}
        </div>

        <div
          slot="depositUsed"
          slot-scope="{ item }"
          :title="$t('common.status')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.depositUsed | priceEUR }}
        </div>

        <div
          slot="rgToRelease"
          slot-scope="{ item }"
          :title="$t('common.status')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.rgToRelease | priceEUR }}
        </div>

        <div
          slot="rgreleased"
          slot-scope="{ item }"
          :title="$t('situations.releasedAmount')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.rgreleased | priceEUR }}
        </div>

        <div
          slot="totalAmountWithReleasedRG"
          slot-scope="{ item }"
          :title="$t('common.status')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.totalAmountWithReleasedRG | priceEUR }}
        </div>

        <div
          slot="tvaamount"
          slot-scope="{ item }"
          :title="$t('common.status')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.tvaamount | priceEUR }}
        </div>

        <div
          slot="totalAmountTTC"
          slot-scope="{ item }"
          :title="$t('situations.totalWithVat')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.totalAmountTTC | priceEUR }}
        </div>

        <div slot="actions" slot-scope="{ item }">
          <router-link
            :to="'/sst/situation/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createSituationModal"
      @create="createSituation"
      :title="$t('situations.situationCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <!-- <text-field label="Date" v-model="situation.date" :inline="false" :edit="true"/> -->
          <many2one-field
            :label="$t('entities.contractorAgreement')"
            v-model="situation.market"
            :fetch="$api.fetchMarkets"
            reference="edifice"
            :columns="columnsMarket"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="market2one"
            :error="errors.has('market2one')"
            data-test="sel-montlhystatement-market"
          ></many2one-field>
          <text-field
            :label="$t('common.sap')"
            v-model="situation.sap"
            :inline="false"
            :edit="true"
          />
        </div>
      </div>
    </modal>

    <modal
      ref="selectProjectModal"
      :title="$t('situations.projectSelection')"
      @create="generateMontlhyStatement"
    >
      <datatable
        :fetch="$api.fetchProjects"
        v-model="selectedProjects"
        :columns="columnsProject"
        selector
        :single-select="false"
        :size="15"
      ></datatable>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Datatable from "../../components/Datatable";

export default {
  name: "situation",
  components: {
    Datatable,
  },
  data() {
    return {
      situation: {
        date: "",
        depositAmount: "",
        depositReleaseDate: "",
        sap: "",
      },
      selectedProjects: [],
      columnsSituation: {
        date: {
          sortable: true,
          title: this.$t('common.date'),
          filter: '',
          sortBy: 'date',
          inputType: 'date'
        },
        market: {
          sortable: true,
          title: this.$t('common.contractorAgreement'),
          filter: '',
          sortBy: 'market.edifice'
        },
        sapReference: {
          sortable: true,
          title: this.$t('markets.columns.marketNumber'),
          filter: '',
          sortBy: 'market.sapReference'
        },
        "agency.company": {
          sortable: true,
          title: this.$t('common.company'),
          filter: '',
          sortBy: 'market.agency.company.name'
        },
        agency: {
          sortable: true,
          title: this.$t('common.agency'),
          filter: '',
          sortBy: 'market.agency.edifice'
        },
        "market.project": {
          sortable: true,
          title: this.$t('common.project'),
          filter: '',
          sortBy: 'market.project.name'
        },
        status: {
          sortable: true,
          title:  this.$t('common.status'),
          filter: '',
          sortBy: 'status'
        },
        totalAmount: this.$t('situations.currentMonthAmount'),//totalAmount
        rgamount: this.$t('situations.rgAmount'),
        depositUsed: this.$t('situations.bondAmount'),
        rgToRelease: this.$t('situations.withheldAmount'),
        rgreleased: this.$t('situations.releasedAmount'),//rgreleased
        totalAmountWithReleasedRG: this.$t('situations.totalAmountWoVat'),
        tvaamount: this.$t('situations.reverseChargeAmount'),
        totalAmountTTC: this.$t('situations.totalAmount')//totalAmountTTC
      },
      columnsMarket: {
        "project.name": this.$t("common.project"),
        "agency.company.name": this.$t("common.agency"),
        edifice: this.$t("common.edifice"),
      },
      columnsProject: {
        name: {
          title: this.$t("common.name"),
          filter: "",
        },
        code: {
          title: this.$t("common.code"),
          filter: "",
        },
        "type.name": {
          title: this.$t("common.type"),
          filter: "",
        },
        manager: {
          title: this.$t("common.manager"),
          filter: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
      userRoleOptions: "auth/getOptions",
    }),
    monthlyStatementBtn() {
      // If the profile doesn't have the permissions, we don't display the buttons.
      if (!this.getRights(this.$entitiesName.MonthlyStatement).update) {
        return [];
      }

      const buttons = [
        {
          name: this.$t("situations.markPaid"),
          action: (item) => {
            this.markAsPaid(item);
          },
          class: "button is-success mb-3 ml-2",
        },
      ];

      // Display cancel button
      if (
        this.userRoleOptions.includes(
          this.$roleOptions.MONTHLY_STATEMENTS.DANGEROUSLY_REVERT
        )
      ) {
        buttons.push({
          name: this.$t("situations.cancelPayment"),
          action: (item) => {
            this.markAsUnpaid(item);
          },
          class: "button is-danger mb-3 ml-2",
        });
      }

      return buttons;
    },
  },
  watch: {
    selectAll() {
      this.selectedProjects = [];
      if (this.selectAll === true) {
        this.items.forEach((item) => {
          this.selectedProjects.push(item);
        });
      }
      this.$emit("input", this.selectedProjects);
    },
  },

  methods: {
    openCreateModal() {
      this.$refs.createSituationModal.open();
    },
    openProjectSelectionModal() {
      this.$refs.selectProjectModal.open();
    },
    createSituation() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.situation.date = new Date();
          axios.post("/situation/create", this.situation).then((response) => {
            if (response.data.success) {
              this.$refs.situationList.refresh();
              this.$refs.createSituationModal.close();
              this.$router.push({
                name: "Situation",
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
    generateMontlhyStatement() {
      if (this.selectedProjects.length === 0) {
        return;
      }
      const projectIds = this.selectedProjects.map((object) => object.id);
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir générer les situations mensuelles pour ces projets ?",
        () => {
          axios.post("/projects/monthlyStatement", projectIds).then(() => {
            this.selectedProjects = [];
            this.$refs.selectProjectModal.close();
            this.$refs.situationList.refresh();
          });
        }
      );
    },

    markAsPaid(item) {
      const ids = item;
      const requests = [];
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir marquer cette situation mensuelle comme payée ?",
        () => {
          ids.forEach((item) => {
            // TODO: Check the if the monthlystatement is locked, skip if it is.

            // Check if the amount is 0 (cannot mark as paid a situation with null amount)
            if (item.totalAmountTTC === 0) {
              return;
            }

            requests.push(
              axios
                .post(`/situation/${item.id}/paid`)
                .then((response) => {
                  response;
                })
                .catch((error) => {
                  console.log(error);
                })
            );
          }),
            Promise.all(requests).finally(() => {
              this.$refs.situationList.refresh();
            });
        }
      );
    },

    markAsUnpaid(item) {
      const requests = [];
      const ids = item;
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir marquer cette situation mensuelle comme non payée ?",
        () => {
          ids.forEach((item) => {
            requests.push(
              axios
                .post(`/situation/${item.id}/dangerously_unpay`)
                .then((response) => {
                  response;
                })
                .catch((error) => {
                  console.log(error);
                })
            );
          }),
            Promise.all(requests).finally(() => {
              this.$refs.situationList.refresh();
            });
        }
      );
    },
  },
};
</script>
