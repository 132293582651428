<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Document">
        <h1 class="title">
          <breadcrumb location="/documents"
                      :text="$t('documents.documents')"></breadcrumb>
          {{ document.filename }}
        </h1>

        <template slot="actions">
          <button class="button is-primary white"
                  @click.stop="openSendToSignatureModal"
                  v-if="canbeSendToSignature && userRoleOptions.includes(
                $roleOptions.DOCUMENT.CAN_SEND_FOR_SIGNATURE)"
                  data-test="sel-document-send-to-signature">
            {{ $t('documents.sendToSignature') }}
          </button>
        </template>
      </page-header>

      <div class="panel infos">
        <div class="panel-heading has-background-primary">{{$t('common.information')}}</div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half">
              <text-field :label="$t('common.name')"
                          :value="document.filename"
                          :readonly="true"/>
              <text-field :label="$t('common.type')"
                          :value="document.type && document.type.name"
                          :readonly="true"/>
              <date-field
                :label="$t('common.date')"
                :value="document.createdAt"
                :readonly="true"/>

              <table class="table is-fullwidth has-background-light">
                <thead>
                <tr>
                  <th class="has-text-center">{{ $t('common.name') }}</th>
                  <th>{{ $t('common.role') }}</th>
                  <th>{{ $t('common.status') }}</th>
                  <th>{{ $t('expressions.validationDate') }}</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(validator, idx) in validators" :key="'validator_' + validator.id + '_' + idx" class="is-size-7">
                    <td>{{ validator.user.name }}</td>
                    <td>{{ validator.user.role.name }}</td>
                    <td>
                      <checkbox-field
                        v-model="validator.isValidated"
                      />
                    </td>
                    <td>{{ validator.validationDate | datetime }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="column is-half">
              <workflow-history
                v-model="workflowComment"
                :entity="document"
                :entityName="WorkflowCodes.DOC.NODE.OBJECT_NAME"
                :entityId="id"
                @updated="fetchData"
              ></workflow-history>
            </div>
          </div>
        </div>
      </div>

      <modal ref="sendToSignatureModal"
             @create="sendToSignature"
             @close="closeModal"
             :action="$t('common.send')"
             :loading="isSending"
             :title="$t('documents.signersModalTitle')">
        <div class="columns">
          <div class="column is-half">
            <h3 class="subtitle is-3">{{ $t('documents.axioneSide') }}</h3>
            <text-field :label="$t('common.name')"
                        v-model="signers[0].name"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <text-field :label="$t('common.email')"
                        v-model="signers[0].email"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <text-field label="Phone Country Code"
                        v-model="signers[0].phoneCountryCode"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <text-field :label="$t('common.phone')"
                        v-model="signers[0].phoneNumber"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <h4 class="subtitle is-4">
              <span>{{ $t('documents.signaturePosition') }}</span>
              <span class="help" :title="$t('documents.positionHelper')">?</span>
            </h4>
            <div class="tabs is-toggle">
              <ul>
                <li :class="{ 'is-active': !signers[0].anchorRequired }" @click="signers[0].anchorRequired = false">
                  <a><span>Manuel</span></a>
                </li>
                <li :class="{ 'is-active': signers[0].anchorRequired }" @click="signers[0].anchorRequired = true">
                  <a><span>Automatique</span></a>
                </li>
              </ul>
            </div>
            <text-field :label="$t('documents.anchorString')"
                        v-model="signers[0].anchorString"
                        v-if="signers[0].anchorRequired"
                        :inline="false"
                        :required="signers[0].anchorRequired"
                        :edit="true">
            </text-field>
          </div>
          <div class="column is-half">
            <h3 class="subtitle is-3">{{ $t('documents.contractorSide') }}</h3>
            <text-field :label="$t('common.name')"
                        v-model="signers[1].name"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <text-field :label="$t('common.email')"
                        v-model="signers[1].email"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <text-field label="Phone Country Code"
                        v-model="signers[1].phoneCountryCode"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <text-field :label="$t('common.phone')"
                        v-model="signers[1].phoneNumber"
                        :inline="false"
                        :edit="true"
                        required>
            </text-field>
            <h4 class="subtitle is-4">
              <span>{{ $t('documents.signaturePosition') }}</span>
              <span class="help" :title="$t('documents.positionHelper')">?</span>
            </h4>
            <div class="tabs is-toggle">
              <ul>
                <li :class="{ 'is-active': !signers[1].anchorRequired }" @click="signers[1].anchorRequired = false">
                  <a><span>Manuel</span></a>
                </li>
                <li :class="{ 'is-active': signers[1].anchorRequired }" @click="signers[1].anchorRequired = true">
                  <a><span>Automatique</span></a>
                </li>
              </ul>
            </div>
            <text-field :label="$t('documents.anchorString')"
                        v-if="signers[1].anchorRequired"
                        v-model="signers[1].anchorString"
                        :inline="false"
                        :required="signers[1].anchorRequired"
                        :edit="true">
            </text-field>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Workflows as WORKFLOWS } from "../../constants/Workflow";

export default {
  name: 'document',
  props: ['id'],
  data () {
    return {
      document: {},
      workflowComment: '',
      WorkflowCodes: WORKFLOWS,
      isSending: false,
      canbeSendToSignature: false,
      validators: [],
      signers: [
        {
          name: '',
          email: '',
          anchorRequired: false,
          anchorString: 'key1',
          phoneCountryCode: '+33'
        },
        {
          name: '',
          email: '',
          anchorRequired: false,
          anchorString: 'key2',
          phoneCountryCode: '+33'
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'states/isEdition',
      userRoleOptions: "auth/getOptions",
    }),
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    fetchData () {
      axios.get(`/contract-document/${this.id}`).then((response) => {
        axios.get(`/wftoken/contract-document/${this.id}`).then((tokenResponse) => {
          axios.get(`/document/${this.id}/validators`).then((validatorResponse) => {
            let tmpDocument = response.data;
            tmpDocument.statusWF = tokenResponse.data;
            this.canbeSendToSignature = tmpDocument.statusWF.node && tmpDocument.statusWF.node.nodeCode === 'doc_validated';
            this.document = tmpDocument;
            this.validators = validatorResponse.data;
          });
        });
      });
    },
    openSendToSignatureModal() {
      this.$refs.sendToSignatureModal.open()
    },
    sendToSignature() {
      this.isSending = true
      this.$validator.validateAll().then((result) => {
        if(!result) {
          this.isSending = false
          return
        }

        // Anchor strings should not be the same
        if(this.signers[0].anchorRequired && this.signers[1].anchorRequired && this.signers[0].anchorString === this.signers[1].anchorString) {
          this.$awn.warning(this.$t('documents.anchorStingsShouldBeDifferent'))
          this.isSending = false
          return
        }

        let payload = {
          docIds: [this.id],
          signers: this.signers
        }

        axios.post('/document/signature/email', payload).then((res) => {
          if(res.data.success === 'sent') {
            this.$awn.success(this.$t('documents.successfullySent'))
            this.$refs.sendToSignatureModal.close()
          } else {
            this.$awn.warning(this.$t('documents.failedSent'))
          }
        }).finally(() => {
          this.isSending = false
        })
      })
    },
    closeModal() {
      this.$refs.sendToSignatureModal.close()
    }
  },
};
</script>

<style scoped>
</style>

