import axios from "axios";
import { globalLocale, i18n } from "../i18n";
import PaginationBuilder from "./Paginate";

export default {
  // agencie list
  // companies list
  fetchAgencies(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/agencies?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchAgenciesAsList(callback) {
    axios.get("/agencies").then((response) => {
      callback(response.data.content);
    });
  },
  fetchCompanies(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/companies?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchCategories(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/category?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchParentCategories(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/category/parent?${paginate.toString()}`)
      .then((response) => response.data);
  },
  //   fetchSubCategories(id, p) {
  //     const paginate = new PaginationBuilder(p);
  //     return axios.get(`/category/${id}?${paginate.toString()}`)
  //       .then(response => response.data);
  //   },
  fetchSubCategoriesAsList(id, callback) {
    axios.get(`/category/${id}/subcategories/list`).then((response) => {
      callback(response.data);
    });
  },
  fetchPrestations(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/prestations?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchUsersByAgency(id, callback) {
    axios.get(`/agency/${id}/users`).then((res) => {
      callback(res.data);
    });
  },
  fetchMarketsByAgency(id, callback) {
    axios.get(`/agency/${id}/markets`).then((res) => {
      callback(res.data);
    });
  },
  // attachement list
  // service order list
  fetchAttachements(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/attachements?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchOrders(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/orders?${paginate.toString()}`).then((res) => res.data);
  },
  fetchOrderWarranties(p, params) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/orders/warranties?${params}&${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchDeposits(p, params) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/deposit?${params}&${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchDepositReferences(p) {
    const paginate = new PaginationBuilder(p);
    paginate.sort.column = "reference";
    return axios
      .get(`/deposit/references?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchValidatedOrders(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/orders/validated?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchImputations(p) {
    // TODO: Retrieve the list of imputations available for this market.project
    //       Set the "Active" imputation of the project as default/preselected
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/imputations?${paginate.toString()}`)
      .then((response) => response.data);
  },
  // bpu template list
  // bpu list
  fetchBpus(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/bpus?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchMarkets(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/markets?${paginate.toString()}`).then((res) => res.data);
  },
  fetchDelegationUsers(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/delegation/users?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchAdminDelegationUsers(p, userId) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/delegation/users/${userId}?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchActiveMarkets(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/markets/active?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchDepositType(callback) {
    let response = [i18n.t("warranties.retentionTypes.basic")];
    if (globalLocale === "fr_FR") {
      response.push(i18n.t("warranties.retentionTypes.withThreshold"));
    }
    response.push(i18n.t("warranties.retentionTypes.nonApplicable"));

    callback(response);
  },
  fetchPaymentType(callback) {
    callback([
      i18n.t("markets.paymentTypes.company"),
      i18n.t("markets.paymentTypes.direct"),
    ]);
  },
  fetchPaymentConditionAsList(callback) {
    axios.get("/market/payment_conditions").then((res) => callback(res.data));
  },
  fetchTVAType(callback) {
    callback([
      i18n.t("markets.vatTypes.basic"),
      i18n.t("markets.vatTypes.tenPercents"),
    ]);
  },
  fetchTemplates(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/bputemplates?${paginate.toString()}`)
      .then((res) => res.data);
  },

  fetchTemplatesAsList(callback) {
    axios.get("/bputemplates/list").then((res) => callback(res.data));
  },
  // bpu template prestation editor
  fetchAllParentCategories(callback) {
    axios.get("/category/allParentCategories").then((res) => {
      callback(res.data.sort((a, b) => a.name > b.name));
    });
  },
  fetchUnits(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/unit?${paginate.toString()}`).then((res) => res.data);
  },
  fetchPrestationReferences(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/prestationreference?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchTypes(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/prestationtype?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchTypesAsList(callback) {
    axios.get("/prestationtypes/list").then((res) => {
      callback(res.data);
    });
  },
  // bpu editor
  fetchSSTs(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/markets?${paginate.toString()}`).then((res) => res.data);
  },
  async fetchBpu(id) {
    return axios.get(`/bpu/${id}`).then((res) => res.data);
  },
  // markets list
  // project list
  fetchProjects(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/projects?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchProjectsFilterByUser(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/projects/user/${id}?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchProjectTypes(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/projecttype?${paginate}`)
      .then((response) => response.data);
  },
  fetchProjectTypesAsList(callback) {
    axios.get("/projecttype/list").then((response) => {
      callback(response.data);
    });
  },
  fetchImputationsByProject(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/project/${id}/imputations?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchPMsByProject(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/project/${id}/pms?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchDSP(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/dsps?${paginate.toString()}`)
      .then((response) => response.data);
  },
  // nro list
  fetchNros(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/nros?${paginate.toString()}`)
      .then((response) => response.data);
  },
  // pm list
  fetchPMs(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/pms?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchRoles(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/roles?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchRolesAsList(callback) {
    axios.get("/roles").then((response) => {
      callback(response.data.content);
    });
  },
  // situation list
  fetchSituations(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/situations?${paginate.toString()}`)
      .then((response) => response.data);
  },
  // users list
  fetchUsers(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/users?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchAttachementsByStatement(id, callback) {
    axios.get(`/situation/${id}/attachements`).then((res) => {
      callback(res.data);
    });
  },
  fetchAttachementsByAgency(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/situation/${id}/agencyattachements?${paginate}`)
      .then((res) => res.data);
  },
  // documents
  fetchDocumentTypes(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/documenttype?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchContractDocuments(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/contract-document/all?${paginate.toString()}`)
      .then((response) => response.data);
  },
  // role
  fetchVisibilityTypes(callback) {
    const roleTypes = [
      i18n.t("roles.visibilities.all"),
      i18n.t("roles.visibilities.projects"),
      i18n.t("roles.visibilities.managerProjects"),
      i18n.t("roles.visibilities.projectsOffices"),
    ];
    if (typeof callback === "function") {
      callback(roleTypes);
    }
    return roleTypes;
  },
  fetchTokens(id, p, filter = "") {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/node/${id}/tokens?filter=${filter}&${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchBPUByMarket(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/market/${id}/bpus?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchBudgetOd(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/budget/od?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchDc4(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/budget/dc4?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchRgCaution(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/budget/warranties?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchQuantitatives(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/budget/quantitatives?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchAttachementsToValidate() {
    return axios
      .get("/dashboard/attachementstovalidate")
      .then((res) => res.data);
  },
  fetchOrdersToValidate() {
    return axios.get("/dashboard/orderstovalidate").then((res) => res.data);
  },
  fetchRunningOrders(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/dashboard/runningorders?${paginate}`)
      .then((res) => res.data);
  },
  fetchRunningOrderTotalAmount() {
    return axios.get("dashboard/runningorders/amount").then((res) => res.data);
  },
  fetchWorkflowNodes() {
    return axios.get("/dashboard/nodes").then((res) => res.data);
  },
  fetchSubCategoriesPage(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/category/${id}/subcategories?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchPrestationsSst(bpuId, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/bpu/${bpuId}/attachement/prestations?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchOscarProjects(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/oscarprojects?${paginate}`).then((res) => res.data);
  },
  fetchOscarProjectsAvailable(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/oscarprojects/available?${paginate}`)
      .then((res) => res.data);
  },
  fetchOscarSubProjects(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/oscarsubprojects?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchOscarSubProjectsForOscarProject(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/oscarproject/${id}/oscarsubprojects?${paginate}`)
      .then((res) => res.data);
  },
  fetchOscarSubProjectsByProject(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/project/${id}/oscarsubprojects?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchOsTemplates(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/orderTemplates?${paginate.toString()}`)
      .then((res) => res.data);
  },
  fetchPaymentGuarantees(p, filter = "") {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/paymentguarantee?${paginate.toString()}&filter=${filter}`)
      .then((response) => response.data);
  },
  fetchContractTemplates(p, documentType = null) {
    if (documentType) {
      p.filters.documentType = documentType;
    }
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/contracttemplate?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchContracts(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/contracts?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchPotentialParents(pagination, id) {
    const paginate = new PaginationBuilder(pagination);
    return axios
      .get(`/contract/${id}/parents?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchMarketForContract(id) {
    return axios.get(`/contract/${id}/markets`).then((res) => res.data);
  },
  fetchPopupNotifications(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/popup-notification/all?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchLatestNotification() {
    return axios.get("/popup-notification/me").then((res) => res.data);
  },
  fetchRequiredRolesForValidation() {
    return axios.get("/role/document-validator").then((res) => res.data);
  },
  fetchUsersByRole(p, roleId) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/users/role/${roleId}?${paginate.toString()}`)
      .then((response) => response.data);
  }
};
