<template>
  <div class="level" v-if="paginate">
    <div class="level-left">
      <div v-if="showOptions" class="field has-addons">
        <p class="control" v-for="(option, index) in options" :key="index">
          <a
            class="button"
            :class="{ 'is-primary': paginate.size === option }"
            @click="setSize(option)"
            >{{ option }}</a
          >
        </p>
      </div>
      <div class="level-left" v-if="bulkActions">
        <button
          v-for="(bulkAction, index) in bulkActions"
          :key="index"
          @click="bulkAction.action(selected)"
          :class="bulkAction.class"
          :disabled="!selected || selected.length === 0"
        >
          {{ bulkAction.name }}
        </button>
      </div>
    </div>
    <div class="lev">
      {{ fromItem }} {{ $t("common.to") }} {{ toItem }} {{ $t("common.on") }}
      {{ paginate.total }} {{ $t("common.elements").toLowerCase() }}
    </div>
    <div class="level-right">
      <div class="field has-addons" v-if="totalPages > 1">
        <p class="control" :class="{ disabled: paginate.page === 1 }">
          <a class="button" @click="goToPage(paginate.page - 1)">{{
            $t("common.previous")
          }}</a>
        </p>

        <p class="control" v-if="paginate.page > 1">
          <a class="button" @click="goToPage(1)">1</a>
        </p>

        <p class="control" v-if="paginate.page > 3">
          <a class="button">...</a>
        </p>

        <p class="control" v-if="paginate.page > 2">
          <a class="button" @click="goToPage(paginate.page - 1)">{{
            paginate.page - 1
          }}</a>
        </p>

        <p class="control">
          <span class="page-input">
            <input
              class="has-background-primary"
              type="text"
              v-model="currentPage"
              @keyup.enter="setPage"
            />
          </span>
        </p>

        <p
          class="control"
          v-if="paginate.page < totalPages && paginate.page + 1 !== totalPages"
        >
          <a class="button" @click="goToPage(paginate.page + 1)">{{
            paginate.page + 1
          }}</a>
        </p>

        <p class="control" v-if="paginate.page < totalPages - 2">
          <a class="button">...</a>
        </p>

        <p class="control" v-if="paginate.page < totalPages">
          <a class="button" @click="goToPage(totalPages)">{{ totalPages }}</a>
        </p>

        <p class="control" :class="{ disabled: paginate.page === totalPages }">
          <a class="button" @click="goToPage(paginate.page + 1)">{{
            $t("common.next")
          }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "paginate",
  props: {
    paginate: Object,
    options: {
      type: Array,
      default: () => [50, 100, 200],
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    bulkActions: {
      type: Array,
    },
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      currentPage: +this.paginate.page,
    };
  },
  computed: {
    fromItem() {
      if (this.paginate.total <= 0) {
        return 0;
      }
      let fromItem = (this.paginate.page - 1) * this.paginate.size + 1;
      if (fromItem > this.paginate.total) {
        fromItem = 1;
        this.goToPage(1);
      }
      return fromItem;
    },
    toItem() {
      return Math.min(
        (this.paginate.page - 1) * this.paginate.size + this.paginate.size,
        this.paginate.total
      );
    },
    totalPages() {
      const total = Math.ceil(this.paginate.total / this.paginate.size);
      return total > 0 ? total : 1;
    },
  },
  watch: {
    currentPage: function () {
      if (
        this.paginate &&
        this.currentPage &&
        this.paginate.page === +this.currentPage
      ) {
        _.debounce(() => {
          this.setPage();
        }, 500); // Let user time to type the number
      }
    },

    "paginate.page": function (v) {
      this.currentPage = v;
    },
  },
  methods: {
    setSize(size) {
      if (!this.paginate) {
        return;
      }

      this.paginate.size = size;
      this.$emit("change", this.paginate);
    },
    setPage() {
      this.goToPage(Number(this.currentPage));
    },
    goToPage(page) {
      if (!this.paginate) {
        return;
      }

      let newPage = page;

      if (newPage <= 0) {
        newPage = 1;
      } else if (newPage > this.totalPages) {
        newPage = this.totalPages;
      }
      this.currentPage = newPage;
      this.$emit("change", {
        ...this.paginate,
        page: newPage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-input {
  padding: 0;

  input {
    color: #fff;
    margin: 0;
    width: 40px;
    height: 35px;

    line-height: 20px;
    border: 0;
    border-radius: 0;
    text-align: center;
  }
}
</style>
